const template = document.createElement('template');

template.innerHTML = /* html */ `
     <style>
        :host {
            display: inline-block;
            width: 100%;
        }
        .graph {
            max-width: 1280px;
            padding: 30px 0px;
            padding-left: 10%;
            width: 90%;
            margin: 0 auto;
        }
        .box {
            float: left;
            width: 15%;
            text-align: center;
            font-size: 24px;
            line-height: 120%;
            height: 100px;
            display: inline-block;
            vertical-align: middle;
            display: flex;
            justify-content: center;
            align-items: center;
            border: 4px solid black;
            border-radius: 10px;
            background-color: var(--bg-light-grey);
        }
        .arrow {
            border: none;
            width: 8%;
            height: 110px;
            background: url('media/icons/arrow-forward.png') no-repeat center center;
            background-size: 50px;
        }
        @media screen and (max-width: 1080px) {
            .graph {
                padding: 20px 0px;
                padding-left: 5%;
                width: 95%;
            }
            .box {
                width: 17.3%;
            }
            .arrow {
                width: 7%;
            }
        }
        @media screen and (max-width: 800px) {
            .box {
                font-size: 20px;
            }
        }
        
        @media screen and (max-width: 600px) {
            .box {
                font-size: 18px;
                height: 80px;
                border: 2px solid black;
            }
            .arrow {
                border: none;
                background-size: 30px;
            }
        }
        
        @media screen and (max-width: 550px) {
            .box {
                font-size: 16px;
            }
        }
    </style>
    
    <div class='section-2'>
        <div class='graph'>
            <div class='box'>
                CAD / 3D<br>Models
            </div>
            <div class='arrow box'></div>
            <div class='box'>
                Generate Synthetic Dataset
            </div>
            <div class='arrow box'></div>
            <div class='box'>
                Train Model
            </div>
            <div class='arrow box'></div>
            <div class='box'>
                Production Ready
            </div>
        </div>
    </div>

`;

export class StartProcessSection extends HTMLElement {
    constructor() {
        super();

        const shadow = this.attachShadow({ mode: 'open' });
        shadow.appendChild(template.content.cloneNode(true));
    }
}

customElements.define('start-process-section', StartProcessSection);
