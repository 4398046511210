const template = document.createElement('template');

template.innerHTML = /* html */ `
     <style>
        :host {
            display: inline-block;
            width: 100%;
        }
        .section {
            padding-top: 80px;
            width: 100%;
            height: auto;
        }
        .title {
            width: 100%;
            font-size: var(--f-size-h1);
            font-weight: bold;
            text-align: center;
        }
        .power-boxes {
            padding: 40px 0px;
            width: auto;
        }
        .power-box-center {
            margin: 0 auto;
            width: 75%;
            max-width: 1200px;
        }
        .power-box {
            background-color: var(--bg-light-grey);
            width: calc(33.3% - 40px);
            text-align: center;
            float: left;
            margin: 20px 0px 0px 0px;
            padding: 30px 0px;
        }
        .power-box-center-margin {
            margin: 20px 60px;
        }
        .power-box-title {
            font-size: 90px;
            padding: 40px 0px;
            font-weight: bold;
            color: darkgrey;
        }
        .power-box-text {
            font-size: 20px;
            font-weight: bold;
            padding: 0px 0px 40px 0px;
        }
        @media screen and (max-width: 1080px) {
            .section {            
                padding-top: 40px;
            }
            .power-boxes {
                padding: 20px 0px;
            }
            .power-box-center {
                width: 85%;
            }
            .power-box-title {
                font-size: 70px;
            }
            .power-box {
                padding: 0px;
            }
        }
        @media screen and (max-width: 820px) {
            .power-box-title {
                font-size: 50px;
            }
        }
        @media screen and (max-width: 700px) {
            .section {
                padding-top: 40px;
            }
            .power-boxes {
                padding: 20px 0px;
            }
            .power-box-center {
                width: 90%;
            }
            .power-box {
                width: calc(33% - 15px);
            }
            .power-box-center-margin {
                margin: 20px 20px;
            }
        }
        @media screen and (max-width: 600px) {
            .power-boxes {
                padding: 10px 0px;
            }
            .power-box-title {
                padding: 20px 0px;
                font-size: 40px;
            }
            .power-box-text {
                padding: 0px 0px 20px 0px;
                font-size: 18px;
            }
        }
    </style>
    
    <div class='section'>
        <div class='title'>The Power of Synthetic Data</div>
        <div class='power-boxes'>
            <div class='power-box-center'>
                <div class='power-box'>
                    <div class='power-box-title'>&#62;80%</div>
                    <div class='power-box-text'>Less real data required</div>
                </div>
                <div class='power-box power-box-center-margin'>
                    <div class='power-box-title'>3x</div>
                    <div class='power-box-text'>Time to Production</div>
                </div>
                <div class='power-box'>
                    <div class='power-box-title'>80%</div>
                    <div class='power-box-text'>Cheaper than Real Data</div>
                </div>
            </div>
        </div>  
    </div>
`;

export class StartPowerSection extends HTMLElement {
    constructor() {
        super();

        const shadow = this.attachShadow({ mode: 'open' });
        shadow.appendChild(template.content.cloneNode(true));
    }
}

customElements.define('start-power-section', StartPowerSection);
