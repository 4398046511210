const template = document.createElement('template');

template.innerHTML = /* html */ `
     <style>
        :host {
            display: inline-block;
            width: 100%;
        }
        .content {
            max-width: 1920px;
            margin: 0 auto;
        }
        .left {
            float: left;
            width: 50%;
            text-align: left;
        }
        .right {
            float: left;
            width: 50%;
            padding: 20px 0px;
            height: 100%;
            margin-top: 10px;
        }
        .section-1 {
            float: left;
            width: 100%;
            padding-top: 40px;
        }
        .section-1-inner {
            max-width: 1080px;
            margin: 0 auto;
        }
        .title {
            padding: 40px 0px 20px 0px;
            font-weight: bold;
            font-size: var(--f-size-h1);
            line-height: 120%;
        }
        .subtitle {
            font-size: 20px;
            line-height: 120%;
            padding-bottom: 40px;
        }
        .title-inner-1 {
            margin: 0 auto;
            max-width: 350px;
        }
        .right-inner {
            margin: 0 auto;
            max-width: 350px;
        }
        .list-entry {
            font-size: 20px;
            padding: 10px 0px 10px 45px;
            background: url('media/icons/cad-icon.png') no-repeat left center;
            background-size: contain;
            margin: 20px 0px;
            background-size: 40px;
            display: flex;
            justify-content: left;
            align-items: center;
        }
        .desktop {
            display: block;
        }
        .mobile {
            display: none;
        }
        
        @media screen and (max-width: 800px) {
            .left {
                width: 60%;
            }
            .right {
                width: 40%;
            }
        }

        @media screen and (max-width: 720px) {
            .left {
                width: 100%;
            }
            .right {
                width: 100%;
                padding: 0px;
                margin-top: 0px;
            }
            .title-inner-1 {
                max-width: 100%;
                margin: 20px;
            }
            .title {
                padding: 0px 0px 10px 0px;
                max-width: 350px;
            }
            .subtitle {
                padding-bottom: 0px;
            }
            .section-1 {
                margin: 0px;
                padding: 0px;
            }
            .right-inner {
                max-width: 100%;
                margin: 0px 20px;
            }
            .list-entry {
                padding: 0px 10px 0px 45px;
                margin: 10px 0px;
                font-size: 16px;
                width: calc(50% - 60px);
                float: left;
                background-size: 40px;
                height: 40px;
            }
            .desktop {
                display: none;
            }
            .mobile {
                display: block;
            }
        }
        
        @media screen and (max-width: 600px) {
            .title {
                max-width: 300px;
            }
        }
    </style>
 
    <div class='section-1'>
        <div class='section-1-inner'>
            <div class='left'>
                <div class='title-inner-1'>
                    <div class='title'>Synthetic Image Data for Object Recognition</div>
                    <div class='subtitle desktop'>Empowering engineers with cost-effective synthetic data based  <br>on CAD and 3D models</div>
                    <div class='subtitle mobile'>Empowering engineers with cost-effective synthetic data based on CAD and 3D models</div>
                </div>
            </div>
            <div class='right'>
                <div class='right-inner'>
                    <div class='list-entry'>
                        <div class='list-entry-text'>CAD & 3D Models</div>
                    </div>
                    <div class='list-entry'>
                        <div class='list-entry-text'>Perfect Ground Truth</div>
                    </div>
                    <div class='list-entry'>
                        <div class='list-entry-text'>Pre-Trained Models</div>
                    </div>
                    <div class='list-entry'>
                        <div class='list-entry-text'>Complete Online Solution</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
`;

export class StartHeaderSection extends HTMLElement {
    constructor() {
        super();

        const shadow = this.attachShadow({ mode: 'open' });
        shadow.appendChild(template.content.cloneNode(true));
    }
}

customElements.define('start-header-section', StartHeaderSection);
