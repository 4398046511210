const template = document.createElement('template');

template.innerHTML = /* html */`
    <style>
        :host {
            display: inline-block;
            width: 100%;
        }

        a:link, a:active, a:visited {
            color: var(--f-black);
            background-color: transparent;
            text-decoration: none;
        }

        a {
            letter-spacing: 0.3px;
        }

        a:hover {
            cursor: pointer;
        }

        p {
            line-height: 150%;
            letter-spacing: 0.3px;
        }
        #footer {
            letter-spacing: 0.4px;
            font-size: 14px;
            text-align: center;
            width: 100%;
            background-color: var(--bg-light-grey);
        }

        .left {
            float: left;
            width: calc(50% - 40px);
            padding: 20px;
            text-align: left;
        }
        .right {
            float: left;
            width: 50%;
            padding-top: 70px;
            height: 100%;
        }
    
        .content {
            max-width: 1920px;
            margin: 0 auto;
        }

        .centered {
            margin: 0 auto;
            max-width: 700px;
        }

        .right p {
            font-size: 18px;
        }

        #title {
            margin-top: 15px;
            margin-bottom: 20px;    
        }

        #subtitle {
            font-size: 18px;
            margin-bottom: 20px;
        }
        
        input {
            width: calc(50% - 40px);
            margin-top: 20px;
            padding: 10px;
            border-color: gray;
            border-radius: 5px;
        }

        #input-name {
            margin-right: 10px;
        }

        #input-mail {
            margin-left: 10px;
        }

        button {
            width: calc(100% - 10px);
            padding: 10px;
            margin: 20px 0px;
            border-radius: 5px;
            border-color: transparent;
            font-weight: bold;
            color: white;
            background-color: gray;
        }
        button:hover {
            cursor: pointer;
        }

        @media screen and (max-width: 600px) {
            .left {
                width: calc(100% - 40px);
            }
            .right {
                display: none;
            }
            input {
            }
        }
    </style>

    <div id='footer'>
        <div style="clear:both;"></div>
        <div class='content'>
            <div class='left'>
                <div class='centered'>
                    <h1 id='title'>SynthVision</h1>
                    <div id='subtitle'>Sign up for early access</div>
                    <div>
                        <input id='input-name' type='text' placeholder='Full Name' />
                        <input id='input-mail' type='text' placeholder='Email' />
                        <button>Sign up</button>
                    </div>
                </div>
            </div>
            <div class='right'>    
                <div class='centered'>
                    <h1>Got a Question?</h1>
                    <p>
                        Contact our support: <br/>
                        <a href='mailto:contact@synthvision.ai'>contact@synthvision.ai</a>
                    </p>
                </div>
            </div>
        </div>

        <div style="clear:both;"></div>
        <div style='padding: 20px 0px 20px 0px'>
            <a href='/'>&copy; 2023 SynthVision</a> | <a href='/imprint/'>Privacy Policy</a> | <a href='mailto:contact@synthvision.ai'>Contact</a>
        </div>
    </div>
`;

export class CustomFooter extends HTMLElement {
    constructor() {
        super();

        this.attachShadow({ mode: 'open' });
        this.shadowRoot!.appendChild(template.content.cloneNode(true));
    }
}

customElements.define('custom-footer', CustomFooter);
