const template = document.createElement('template');

template.innerHTML = /* html */ `
     <style>
        :host {
            display: inline-block;
            width: 100%;
        }
        .section {
            width: 100%;
            height: auto;
        }
        .title {
            width: 100%;
            font-size: var(--f-size-h1);
            font-weight: bold;
            text-align: center;
        }
        .power-boxes {
            padding: 0px 0px;
            width: auto;
        }
        .power-box-center {
            margin: 0 auto;
            width: 75%;
            max-width: 1200px;
        }
        .power-box {
            background-color: var(--bg-light-grey);
            width: calc(33.3% - 40px);
            text-align: center;
            float: left;
            margin: 20px;
        }
        .power-box-center-margin {
            margin: 20px 60px;
        }
        .power-box-title {
            font-size: 40px;
            padding: 40px 0px 20px 0px;
            font-weight: bold;
        }
        .power-box-elements {
            font-size: 20px;
            font-weight: bold;
            padding: 0px 40px 40px 40px;
            text-align: left;
        }
        @media screen and (max-width: 1080px) {
            .power-boxes {
                padding: 0px;
            }
            .power-box-center {
                width: 100%;
            }
            .power-box {
                padding: 0px;
            }
        }
        @media screen and (max-width: 600px) {
            .power-box {
                width: calc(100% - 40px);
            }
            .power-box-title {
                padding: 20px 0px 0px 0px;
                font-size: 30px;
            }
            .power-box-text {
                padding: 0px 0px 20px 0px;
                font-size: 18px;
            }
            .power-box-elements {
                padding-top: 0px;
                padding-bottom: 10px;
            }
        }
    </style>
    
    <div class='section'>
        <div class='power-boxes'>
            <div class='power-box-center'>
                <div class='power-box'>
                    <div class='power-box-title'>Free</div>
                    <ul class='power-box-elements'>
                        <li class='list-element'>10.000 Images</li>
                        <li class='list-element'>10 3D / CAD models</li>
                        <li class='list-element'>Classification</li>
                        <li class='list-element'>YOLO & COCO Export</li>
                        <li class='list-element'>Pre-trained YOLOv8</li>
                    </ul>
                </div>
                <div class='power-box'>
                    <div class='power-box-title'>Pro</div>
                    <ul class='power-box-elements'>
                        <li class='list-element'>100.000 Images</li>
                        <li class='list-element'>50 3D / CAD models</li>
                        <li class='list-element'>Classification & Segmentation</li>
                        <li class='list-element'>Export in all formats</li>
                        <li class='list-element'>Pre-trained YOLOv8 & Faster R-CNN</li>
                        <li class='list-element'>Fine-tuning with real images</li>
                    </ul>
                </div>
                <div class='power-box'>
                    <div class='power-box-title'>Ultimate</div>
                    <ul class='power-box-elements'>
                        <li class='list-element'>1.000.000 Images</li>
                        <li class='list-element'>unlimited 3D / CAD models</li>
                        <li class='list-element'>Classification & Segmentation</li>
                        <li class='list-element'>Export in all formats</li>
                        <li class='list-element'>Pre-trained YOLOv8 & Faster R-CNN</li>
                        <li class='list-element'>Fine-tuning with real images</li>
                        <li class='list-element'>Improve realism of models with GANs</li>
                    </ul>
                </div>
            </div>
        </div>  
    </div>
`;

export class PricingTiersSection extends HTMLElement {
    constructor() {
        super();

        const shadow = this.attachShadow({ mode: 'open' });
        shadow.appendChild(template.content.cloneNode(true));
    }
}

customElements.define('pricing-tiers-section', PricingTiersSection);
