const template = document.createElement('template');

template.innerHTML = /* html */`
    <style>
        :host {
            height: 60px;
            width: 100%;
            display: block;
        }

        @media screen and (max-width: 600px) {
            .mobile {
                display: block;
            }
            .desktop {
                display: none;
            }
        }

        @media screen and (min-width: 601px) {
            .mobile {
                display: none;
            }
            .desktop {
                display: block;
            }
        }

        a:link, a:active, a:visited {
            color: var(--f-black);
            background-color: transparent;
            text-decoration: none;
        }

        a {
            letter-spacing: 0.3px;
        }

        p {
            line-height: 150%;
            letter-spacing: 0.3px;
        }

        .topnav {
            background-color: var(--bg-light-grey);
            z-index: 5;
            position: fixed;
            width: 100%;
            height: 60px;
        }

        #topnavCenter {
            display: table-cell;
            vertical-align: middle;
        }

        .topnav .icon {
            padding: 16px 18px 0px 18px;
            display: none;
        }

        .topnavLeft {
            text-align: center;
            float: left;
            padding: 12px 0px 10px 15px;
        }

        .links {
            padding: 0px 10px 4px 10px;
            text-align: center;
            text-decoration: none;
            font-size: 23px;
        }

        #login-btn {
            right: 10px;
            position: fixed;
        }

        @media screen and (max-width: 600px) {
            .topnav {
                background-color: var(--bg-light-grey);
            }
            .links {
                padding: 7px 10px 4px 7px;
                text-align: center;
                text-decoration: none;
                font-size: 20px;
            }
            /*
            .topnav a:not(:first-child) {
                display: none
            }
            */
            .topnav div.icon {
                float: right;
                display: block;
            }
            #currentPage {
                display: block;
            }
            .topnav.open {
                height: 100%;
            }
            .topnav.open .icon {
                position: absolute;
                right: 0;
                top: 0;
            }
            .topnav.open a {
                display: block;
                margin-bottom: 5%;
                font-size: 30px;
            }
            .topnav.open a:focus, .topnav.open a:active {
                -webkit-tap-highlight-color: transparent;
                -webkit-touch-callout: none;
                -webkit-user-select: none;
                -khtml-user-select: none;
                -moz-user-select: none;
                -ms-user-select: none;
                user-select: none;
            }
            .topnavLeft.open {
                display: table;
                top: 0px;
                margin: 0px;
                padding: 0px;
                position: absolute;
                width: 100%;
                height: 100%;
                background-color: white;
                -webkit-animation: fadein 0.3s;
                -moz-animation: fadein 0.3s;
                -ms-animation: fadein 0.3s;
                -o-animation: fadein 0.3s;
                animation: fadein 0.3s;
            }
            #mobileLogo {
                display: block;
                float: left;
                background-image: url("/media/icons/logo.svg");
                background-size: 40px;
                background-repeat: no-repeat;
                margin: 0px 0px 0px 0px;
                padding: 5px 0px 0px 40px;
                height: 40px;
                min-width: 180px;
                font-size: 25px;
                font-weight: bold;
            }

            .hamburger {
                padding: 0 0;
                display: inline-block;
                cursor: pointer;
                transition-property: opacity, filter;
                transition-duration: 0.15s;
                transition-timing-function: linear;
                font: inherit;
                color: inherit;
                text-transform: none;
                background-color: transparent;
                border: 0;
                margin: 0;
                overflow: visible;
            }
            .hamburger-box {
                width: 30px;
                height: 35px;
                display: inline-block;
                position: relative;
            }
            .hamburger-inner {
                display: block;
                top: 50%;
                margin-top: -5px;
            }
            .hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
                width: 28px;
                height: 3px;
                background-color: black;
                border-radius: 4px;
                position: absolute;
                transition-property: transform;
                transition-duration: 0.15s;
                transition-timing-function: ease;
            }
            .hamburger-inner::before, .hamburger-inner::after {
                content: '';
                display: block;
            }
            .hamburger-inner::before {
                top: -9px;
            }
            .hamburger-inner::after {
                bottom: -9px;
            }
            .hamburger--squeeze .hamburger-inner {
                transition-duration: 0.075s;
                transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
            }
            .hamburger--squeeze .hamburger-inner::before {
                transition: top 0.075s 0.12s ease, opacity 0.075s ease;
            }
            .hamburger--squeeze .hamburger-inner::after {
                transition: bottom 0.075s 0.12s ease, transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19);
            }
            .hamburger--squeeze.is-active .hamburger-inner {
                transform: rotate(45deg);
                transition-delay: 0.12s;
                transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            }
            .hamburger--squeeze.is-active .hamburger-inner::before {
                top: 0;
                opacity: 0;
                transition: top 0.075s ease, opacity 0.075s 0.12s ease;
            }
            .hamburger--squeeze.is-active .hamburger-inner::after {
                bottom: 0;
                transform: rotate(-90deg);
                transition: bottom 0.075s ease, transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
            }
        }

        @media screen and (min-width: 601px) {
            #desktopLogo {
                display: block;
                float: left;
                background-image: url("/media/icons/logo.svg");
                background-size: 40px;
                background-repeat: no-repeat;
                margin: 10px 12px 0px 20px;
                padding: 3px 0px 0px 55px;
                min-height: 50px;
                max-height: 50px;
                min-width: 180px;
                font-size: 28px;
                font-weight: bold;
            }
            .topnavLeft {
                padding: 20px 0px 10px 15px;
            }
            #currentPage {
                display: none;
            }
            #verticalLine {
                margin: 10px 0px 0px 5px;
                min-height: 37px;
                max-height: 37px;
                padding: 1px;
                float: left;
                border-right: 1.3px solid rgba(0, 0, 0, 0.3);
            }
        }

        @media screen and (min-width: 601px) and (max-width: 750px) {
            .links {
                padding: 0px 7px 5px 0px;
                text-align: center;
                text-decoration: none;
                font-size: 20px;
            }
            #desktopLogo {
                margin: 9px 7px 5px 10px;
                min-height: 40px;
                max-height: 40px;
                min-width: 150px;
            }
        }
    </style>

    <div class='topnav' id='topnavBar'>
        <div class='widthrestriction'>
            <a href='/' class='desktop' id='desktopLogo'>SynthVision</a>
            <!--a href='/' class='links' id='verticalLine'></a-->
            <div id='topnavLeftId' class='topnavLeft'>
                <div id='topnavCenter'>
                <a href='/' class='links mobile' id='mobileLogo'>SynthVision</a>
                <!--a href='/' class='links mobile'>Home</a>
                <a href='/pricing/' class='links'>Pricing</a-->
                <a href='https://app.synthvision.ai/' class='links' id='login-btn'>Login</a>
                <!--a href='/research/' class='links'>Reserach</a-->
            </div>
        </div>
        <!--div href='javascript:void(0);' class='icon' id='mobile-btn'>
            <div class='hamburger hamburger--squeeze'>
                <div class='hamburger-box'>
                    <div class='hamburger-inner'>
                    </div>
                </div>
            </div>
            </a>
        </div-->
    </div>
`;

export class CustomNavigation extends HTMLElement {
    constructor() {
        super();

        const shadow = this.attachShadow({ mode: 'open' });
        shadow.appendChild(template.content.cloneNode(true));
    }

    // mobile menu
    toggleMenu() {
        const x = this.shadowRoot!.getElementById('topnavBar')!;
        // open
        if (x.className === 'topnav') {
            x.className += ' open';
            this.shadowRoot!.getElementById('mobileLogo')!.style.display = 'none';
            this.shadowRoot!.getElementById('topnavLeftId')!.className += ' open';
        } else {
            // close
            x.className = 'topnav';
            this.shadowRoot!.getElementById('mobileLogo')!.style.display = 'block';
            this.shadowRoot!.getElementById('topnavLeftId')!.className = 'topnavLeft';
        }
        const linkElements = this.shadowRoot!.getElementById('topnavCenter')!.children;
        for (let i = 0; i < linkElements.length; i++) {
            if (linkElements[i].className !== 'socialMediaMobile') {
                if (linkElements[i].className === 'links') {
                    linkElements[i].className += ' open';
                } else {
                    linkElements[i].className = 'links';
                }
            }
        }
    }

    /*
    connectedCallback() {
        // menu toggle button
        const forEach = function(t: any, o: any, r: any) {
            if (Object.prototype.toString.call(t) === '[object Object]') {
                // eslint-disable-next-line guard-for-in
                for (const c in t) {
                    Object.prototype.hasOwnProperty.call(t, c);
                    o.call(
                        r, t[c], c, t,
                    );
                }
            } else {
                for (let e = 0, l = t.length; l > e; e++) {
                    o.call(
                        r, t[e], e, t,
                    );
                }
            }
        };

        const toggle = () => {
            this.toggleMenu();
            const hamburgers = this.shadowRoot!.querySelectorAll('.hamburger')!;
            if (hamburgers.length > 0) {
                forEach(hamburgers, () => {
                    hamburgers[0].classList.toggle('is-active');
                }, null);
            }
        };

        this.shadowRoot!.getElementById('mobile-btn')!.addEventListener('click', toggle.bind(this));

    }
    */
}

customElements.define('custom-navigation', CustomNavigation);
