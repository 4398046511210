const template = document.createElement('template');

template.innerHTML = /* html */ `
     <style>
        :host {
            display: inline-block;
            width: 100%;
        }
        .section-5 {
            width: calc(100% - 160px);
            height: auto;
            padding: 60px 80px 80px 80px;
            max-width: 1200px;
            margin: 0 auto;
        }
        .title {
            width: 100%;
            font-size: var(--f-size-h1);
            font-weight: bold;
            text-align: center;
            padding-bottom: 40px;
        }
        .application-box {
            width: calc(25% - 30px);
            float: left;
            text-align: center;
            font-weight: bold;
        }
        .application-box-center-left {
            margin-left: 40px;
            margin-right: 20px;
        }
        .application-box-center-right {
            margin-left: 20px;
            margin-right: 40px;
        }
        .application-box-title {
            padding: 10px 0px 0px 0px;
            font-size: var(--f-size-h3);
        }
        @media screen and (max-width: 600px) {
            .section-5 {
                padding: 40px 0px 80px 0px;
                width: calc(100% - 40px);
            }
            .title {
                padding-bottom: 20px;
            }
            .application-box {
                width: calc(25% - 20px);
            }
            .application-box-center-left {
                margin-left: 20px;
                margin-right: 10px;
            }
            .application-box-center-right {
                margin-left: 10px;
                margin-right: 20px;
            }
        }
    </style>
    
    <div class='section-5'>
        <div class='title'>Application Areas</div>
        <div class='application-boxes'>
            <div class='application-box'>
                <img src='/media/images/robotics.png' width="100%" />
                <div class='application-box-title'>Robotics</div>
            </div> 
            <div class='application-box application-box-center-left'>
                <img src='/media/images/sorting.png' width="100%" />
                <div class='application-box-title'>Sorting</div>
            </div> 
            <div class='application-box application-box-center-right'>
                <img src='/media/images/autonomous-driving.png' width="100%" />
                <div class='application-box-title'>Autonomous Driving</div>
            </div> 
            <div class='application-box'>
                <img src='/media/images/personal-projects.jpg' width="100%" />
                <div class='application-box-title'>Personal Projects</div>
            </div>
        </div>
    </div>
`;

export class StartApplicationsSection extends HTMLElement {
    constructor() {
        super();

        const shadow = this.attachShadow({ mode: 'open' });
        shadow.appendChild(template.content.cloneNode(true));
    }
}

customElements.define('start-applications-section', StartApplicationsSection);
