const template = document.createElement('template');

template.innerHTML = `

`;

export class HelloWorld extends HTMLElement {
    constructor() {
        super();

        const shadow = this.attachShadow({ mode: 'open' });
        shadow.appendChild(template.content.cloneNode(true));
    }
}

customElements.define('hello-world', HelloWorld);
