const template = document.createElement('template');

template.innerHTML = /* html */ `
     <style>
        :host {
            display: inline-block;
            width: 100%;
        }
        .section-4 {
            width: auto;
            max-width: 1200px;
            margin: 0 auto;
            height: auto;
            padding: 60px 80px 80px 80px;
        }
        .title {
            width: 100%;
            font-size: var(--f-size-h1);
            font-weight: bold;
            text-align: center;
        }
        .entry {
            width: 100%;
            float: left;
            padding: 40px 0px 0px 0px;
        }
        .entry-title {
            font-size: var(--f-size-h2);
            font-weight: bold;
            width: 25%;
            float: left;
        }
        .entry-content {
            font-size: 20px;
            width: 75%;
            float: left;
        }
        @media screen and (max-width: 1080px) {
            .section-4 {
                padding: 40px 20px 80px 20px;
            }
            .entry-title {
                width: 100%;
            }
            .entry-content {
                width: 100%;
            }
            .entry {
                padding: 30px 0px 0px 0px;
            }
        }
        @media screen and (max-width: 700px) {
            .section-4 {
                padding-top: 20px;
            }
            .entry {
                padding: 20px 0px 0px 0px;
            }
        }

    </style>
    
    <div class='section-4'>
        <div class='title'>What we do</div>
        <div class='entry'>
            <div class='entry-title'>Synthetic Data Generation</div>
            <div class='entry-content'>We generate thousands of synthetic images from any type of CAD or 3D model. Our process ensures that an object detection can be trained with synthetic data but afterwards works on real images.</div>
        </div>
        <div class='entry'>
            <div class='entry-title'>Pre-Trained Models</div>
            <div class='entry-content'>We automatically train industry leading object detection algorithms, from YOLOv8 to Faster R-CNN.</div>
        </div>
        <div class='entry'>
            <div class='entry-title'>Customization</div>
            <div class='entry-content'>We offer extensive dataset customization options to support your specific use cases. Besides fine-tuning with real labeled images, we offer training of GANs to improve the realism of the 3D models.</div>
        </div>
        <div class='entry'>
            <div class='entry-title'>Research</div>
            <div class='entry-content'>Our engineers have years of experience and will help you for all kind of machine learning tasks related to computer vision.</div>
        </div>
    </div>
`;

export class StartServicesSection extends HTMLElement {
    constructor() {
        super();

        const shadow = this.attachShadow({ mode: 'open' });
        shadow.appendChild(template.content.cloneNode(true));
    }
}

customElements.define('start-services-section', StartServicesSection);
