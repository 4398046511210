const template = document.createElement('template');

template.innerHTML = /* html */ `
     <style>
        :host {
            display: inline-block;
            width: 100%;
        }
        .content {
            max-width: 1920px;
            margin: 0 auto;
        }
        .section-1 {
            float: left;
            width: 100%;
            padding-top: 40px;
        }
        .section-1-inner {
            max-width: 1080px;
            margin: 0 auto;
            padding: 20px;
        }
        .title {
            padding: 20px 0px 10px 0px;
            font-weight: bold;
            font-size: var(--f-size-h1);
            line-height: 120%;
            text-align: center;
        }
        .subtitle {
            font-size: 20px;
            line-height: 120%;
            padding-bottom: 40px;
        }
        .title-inner-1 {
            margin: 0 auto;
            max-width: 350px;
        }
        .right-inner {
            margin: 0 auto;
            max-width: 350px;
        }
        
        @media screen and (max-width: 800px) {
            .left {
                width: 60%;
            }
            .right {
                width: 40%;
            }
        }

        @media screen and (max-width: 720px) {
            .left {
                width: 100%;
            }
            .right {
                width: 100%;
                padding: 0px;
                margin-top: 0px;
            }
            .title-inner-1 {
                max-width: 100%;
                margin: 20px;
            }
            .subtitle {
                padding-bottom: 0px;
            }
            .section-1 {
                margin: 0px;
                padding: 0px;
            }
            .section-1-inner {
                padding-bottom: 0px;
            }
        }
    </style>
 
    <div class='section-1'>
        <div class='section-1-inner'>
            <div class='title'>Pricing</div>
            <!--div class='subtitle'>
                Cheper than real images.<br>
                Free for research and education.
            </div-->
        </div>
    </div>
`;

export class PricingHeaderSection extends HTMLElement {
    constructor() {
        super();

        const shadow = this.attachShadow({ mode: 'open' });
        shadow.appendChild(template.content.cloneNode(true));
    }
}

customElements.define('pricing-header-section', PricingHeaderSection);
