const template = document.createElement('template');

template.innerHTML = /* html */ `
    <style>
        :host {
            display: inline-block;
            width: 100%;
        }
        .content {
            max-width: 1920px;
            margin: 0 auto;
        }
    </style>
 
    <div>
        <custom-navigation></custom-navigation>

        <div class='content'>
            <start-header-section></start-header-section>
            <start-process-section></start-process-section>
            <start-power-section></start-power-section>
            <start-services-section></start-services-section>
            <start-applications-section></start-applications-section>
            <pricing-header-section></pricing-header-section>
            <pricing-tiers-section></pricing-tiers-section>
        </div>

        <custom-footer></custom-footer>
    </div>
`;

export class StartPage extends HTMLElement {
    constructor() {
        super();

        const shadow = this.attachShadow({ mode: 'open' });
        shadow.appendChild(template.content.cloneNode(true));
    }
}

customElements.define('start-page', StartPage);
